import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reactToWebComponent from 'react-to-webcomponent';
import PropTypes from 'prop-types';



export class JobListing extends React.Component {

  // create a state for the list of the jobs
  state = {
    jobs: [],
    settings: {}
  };

  // fetch the jobs listing data from the server
  // and set the state
  componentDidMount() {
    fetch(`https://job-posting.ui-chunx.com/api/job-posts-open/${this.props.shopid}`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({jobs: data.jobPosts.filter((job) => job?.status === 'active')});
        this.setState({settings: data.settings});
        console.log(data.settings);
        //console.log(this.state.jobs);
      });
  }

  render() {

    const dynamicStyle = `
    .job-listing .job > a:hover {
      background-color: ${this.state.settings.job_list_hover_color};
    }
    .job-listing ul {
      list-style: none;
    }
    .jobs-listing ul li {
      list-style: none;
    }
    .jobs-listing ul li::before {
      display: none;
    }
    .job-listing .location svg path, .job-listing .job-type svg path{
      fill: ${this.state.settings.job_list_location_color} !important;
    }
    .job-listing {
      background-color: ${this.state.settings.job_list_background_color};
    }
    .job-listing .job > a {
      background-color: ${this.state.settings.job_list_background_color};
    }
    .job-listing .job a .salary {
      color: ${this.state.settings.job_list_salary_text_color};
      background-color: ${this.state.settings.job_list_salary_background_color};
    }
    .job-listing .job a .job-right a {
      background-color: ${this.state.settings.job_list_button_color};
    }
  `;


  const employmentTypes = {
    'FULL_TIME': 'Full-time',
    'PART_TIME': 'Part-time',
    'CONTRACT': 'Contract',
    'INTERNSHIP': 'Internship',
    'TEMPORARY': 'Temporary'
  };

    // display the jobs list
    return (
      this.state.jobs.length > 0 ? (
      <div className="job-listing">
        <style>{dynamicStyle}</style>
        <ul>
          {this.state.jobs.map((job) => (
            <li className="job" key={job.id}>
              
              <a href={job.url}>
                <div className="job-left">
                  <h3 style={{ color: this.state.settings.job_list_title_color }}>{job.title}</h3>
                  <div className="location">
                    {job?.streetAddress || job?.postalCode || job?.addressLocality || job?.addressRegion ? (
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox='0 0 48 48' height="48" width="48"><path d="M24 23.5q1.45 0 2.475-1.025Q27.5 21.45 27.5 20q0-1.45-1.025-2.475Q25.45 16.5 24 16.5q-1.45 0-2.475 1.025Q20.5 18.55 20.5 20q0 1.45 1.025 2.475Q22.55 23.5 24 23.5Zm0 16.55q6.65-6.05 9.825-10.975Q37 24.15 37 20.4q0-5.9-3.775-9.65T24 7q-5.45 0-9.225 3.75Q11 14.5 11 20.4q0 3.75 3.25 8.675Q17.5 34 24 40.05ZM24 44q-8.05-6.85-12.025-12.725Q8 25.4 8 20.4q0-7.5 4.825-11.95Q17.65 4 24 4q6.35 0 11.175 4.45Q40 12.9 40 20.4q0 5-3.975 10.875T24 44Zm0-23.6Z"/></svg>
                    ) : (
                      ''
                    )}
                    <span style={{ color: this.state.settings.job_list_location_color }}>
                    {job?.streetAddress ? job?.streetAddress : ''} {job?.postalCode ? job?.postalCode : ''} {job?.addressLocality ? job?.addressLocality : ''} {job?.addressRegion ? job?.addressRegion : ''}
                    </span>
                  </div>
                  <div className="job-type">
                    {job?.employmentType ? (
                      <>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M160-120q-33 0-56.5-23.5T80-200v-440q0-33 23.5-56.5T160-720h160v-80q0-33 23.5-56.5T400-880h160q33 0 56.5 23.5T640-800v80h160q33 0 56.5 23.5T880-640v440q0 33-23.5 56.5T800-120H160Zm0-80h640v-440H160v440Zm240-520h160v-80H400v80ZM160-200v-440 440Z"/></svg>
                        <span style={{ color: this.state.settings.job_list_location_color }}>
                          {employmentTypes[job.employmentType]}
                        </span>
                      </>
                    ) : null}
                  </div>
                </div>
                <div className="job-right">
                  {job?.currency && job?.minSalary && job?.maxSalary ? (
                    <div className="salary">{ job?.currency + ' ' + job?.minSalary + ' - ' + job?.maxSalary  }</div>
                  ) : ('')}
                  <a href={job?.url} rel="noopener noreferrer">
                    {this.state.settings.job_list_button_text || 'View'}
                  </a>
                </div>
              </a>
            </li>
          ))}
        </ul>
      </div>
      ) : (''
      )
    );
    

  }
}

JobListing.propTypes = {
  postid: PropTypes.string,
}

class WebComRef extends reactToWebComponent(JobListing, React, ReactDOM, {
  props: {
    shopid: "ref"
  },
}) {}

customElements.define("job-listing", WebComRef);